import React, { useEffect, useRef } from "react";
import "../Global.css";

function BubbleContainer({ chat }) {
  const ref = useRef(null);
  useEffect(() => {
    if (chat.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [chat.length, ref]);

  const getToday = () => {
    let date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return `${day > 9 ? day : "0" + day}/${
      month > 9 ? month : "0" + month
    }/${date.getFullYear()}`;
  };

  const getTime = (date) => {
    let tempDate = new Date(date);
    const month =
      tempDate.getMonth() + 1 < 10
        ? "0" + (tempDate.getMonth() + 1)
        : tempDate.getMonth() + 1;
    const cDate =
      tempDate.getDate() < 10 ? "0" + tempDate.getDate() : tempDate.getDate();
    tempDate = new Date(`${tempDate.getFullYear()}-${month}-${cDate}`);
    const msgDate = {
      date: `${cDate}/${month}/${tempDate.getFullYear()}`,
      time: tempDate.getTime(),
    };
    const today = getToday();
    if (today === msgDate) {
      return "Today";
    } else {
      return msgDate;
    }
  };

  return (
    <div className="chat-main">
      {chat
        ? chat.map((ch, index, arr) => {
            const key = index + 1;
            getTime(ch.date);
            return (
              <>
                {index === 0 && (
                  <div className="day visible">
                    {getTime(ch.date).date === getToday()
                      ? "Today"
                      : getTime(ch.date).date}
                  </div>
                )}
                <div
                  className={`bubble ${ch.fromSelf ? "self" : ""}`}
                  style={
                    ch.consecutive
                      ? {
                          marginTop: "2px",
                          marginBottom:
                            index === chat.length - 1 ? "10px" : "0px",
                        }
                      : {
                          marginTop: "50px",
                          marginBottom:
                            index === chat.length - 1 ? "10px" : "0px",
                        }
                  }
                  key={key}
                >
                  <div>{ch.message}</div>
                  <div className="time">{ch.time}</div>
                </div>

                <div
                  className={
                    index < arr.length - 1
                      ? getTime(ch.date).time !==
                        getTime(arr[index + 1].date).time
                        ? "day visible"
                        : "day"
                      : "day"
                  }
                >
                  {index < arr.length - 1
                    ? getTime(ch.date).time !==
                      getTime(arr[index + 1].date).time
                      ? getTime(arr[index + 1].date).date
                      : ""
                    : ""}
                </div>
              </>
            );
          })
        : ""}
      <div ref={ref}></div>
    </div>
  );
}

export default BubbleContainer;
