import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChatHome from "./pages/ChatHome";
import Forget from "./pages/Forget";
import Login from "./pages/Login";
import Register from "./pages/Register";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/" element={<Login />}></Route>
        <Route path="/chat" element={<ChatHome />}></Route>
        <Route path="/forget" element={<Forget />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
