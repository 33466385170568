import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { SlOptionsVertical } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import "../Global.css";
import imageDef from "../assets/google-contacts.png";

function ChatHeader({ socket, setToggleProfile, presentUser, setPresentUser }) {
  const navigate = useNavigate();
  const [isOption, setIsOption] = useState(false);
  const optDiv = useRef();
  const toggle = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (
        !optDiv.current.contains(event.target) &&
        !toggle.current.contains(event.target)
      ) {
        setIsOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  return (
    <>
      <div className="chat-header">
        <div className="profile-pic">
          <img
            src={`${
              presentUser.isAvatarImageSet ? presentUser.avatarImage : imageDef
            }`}
            alt={imageDef}
            srcSet=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <h3>{presentUser.name}</h3>
        <div ref={toggle}>
          <SlOptionsVertical
            className="icon"
            onClick={() => {
              setIsOption(!isOption);
            }}
          />
        </div>
        <ul ref={optDiv} className={`options ${isOption ? "grow" : "  "}`}>
          <li
            className="profile option"
            onClick={() => {
              setToggleProfile(true);
            }}
          >
            Profile
          </li>
          <li
            className="option logout-btn"
            onClick={() => {
              localStorage.clear();
              navigate("/", { state: { message: "Logout Succussful..!" } });
            }}
          >
            Logout
          </li>
        </ul>
      </div>
      {/* {console.log("chat-header rendered")} */}
    </>
  );
}

export default ChatHeader;
