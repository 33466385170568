import React from "react";
import { Detector } from "react-detect-offline";
import { IoCloudOffline } from "react-icons/io5";

const CheckConnection = (props) => {
  return (
    <>
      <Detector
        render={({ online }) =>
          online ? (
            props.children
          ) : (
            <div
              style={{
                color: "#d9d9d9",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <IoCloudOffline style={{ fontSize: "4rem" }} />
              <h1>You are offline</h1>
            </div>
          )
        }
      />
    </>
  );
};

export default CheckConnection;
