import axios from "axios";
import { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import imgDef from "../assets/google-contacts.png";
import { getUserRoute, updateUserDetails } from "../utils/APIRoutes";
import ImageUploader from "./ImageUploader";
import Loader from "./Loader";

function Profile({ socket, currentUser, setToggleProfile, selfProfile }) {
  const [presentUser, setCurrentUser] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [isEditEmail, setIsEditEmail] = useState(false);
  const [info, setInfo] = useState({ name: "", email: "" });
  const handleChange = (event) => {
    console.log(event.target.name);
    console.log(event.target.value);
    const { name, value } = event.target;
    setInfo((pre) => {
      return { ...pre, [name]: value };
    });
    console.log(info);
  };
  const handleUpdate = (event) => {
    const value = event.target.id === "name" ? info.name : info.email;
    if (event.target.id === "name") {
      setIsEditName(!isEditName);
    } else {
      setIsEditEmail(!isEditEmail);
    }
    axios.post(updateUserDetails, {
      id: presentUser._id,
      update: event.target.id,
      value: value,
    });
  };

  useEffect(() => {
    axios.get(`${getUserRoute}/${currentUser}`).then((data) => {
      setCurrentUser(data.data);
      setInfo({ name: data.data.name, email: data.data.email });
      setIsReady(true);
    });
  }, [currentUser]);

  return (
    <>
      {isReady ? (
        <div className="user-profile">
          {
            <ImageUploader
              setShowImageUploader={setShowImageUploader}
              showImageUploader={showImageUploader}
              id={presentUser._id}
              user={presentUser}
              setUser={setCurrentUser}
              socket={socket}
            />
          }
          <div
            className="close-btn"
            onClick={() => {
              setToggleProfile(false);
            }}
          >
            +
          </div>
          <div className="profile-pic">
            <img
              src={
                presentUser.isAvatarImageSet ? presentUser.avatarImage : imgDef
              }
              alt="profile-pic
  "
              srcSet=""
            />
            {selfProfile && (
              <BsPencilSquare
                className="edit-icon"
                onClick={() => {
                  setShowImageUploader(!showImageUploader);
                }}
              />
            )}
          </div>
          <div className="user-name">@{presentUser.username}</div>
          <label htmlFor="">Name</label>
          <p>
            {!isEditName ? (
              info.name
            ) : (
              <input
                className="edit-input"
                type="text"
                name="name"
                autoFocus={true}
                value={info.name}
                onChange={handleChange}
              />
            )}
            {selfProfile &&
              (!isEditName ? (
                <BsPencilSquare
                  className="edit-icon"
                  onClick={() => {
                    setIsEditName(!isEditName);
                  }}
                />
              ) : (
                <>
                  <button
                    className="edit-update-btn"
                    id="name"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                  <span
                    className="exit"
                    onClick={() => {
                      setIsEditName(false);
                      setInfo((pre) => {
                        return { ...pre, name: presentUser.name };
                      });
                    }}
                  >
                    {" "}
                    X{" "}
                  </span>
                </>
              ))}
          </p>
          <label htmlFor="">Email</label>
          <p>
            {!isEditEmail ? (
              info.email
            ) : (
              <input
                className="edit-input"
                name="email"
                autoFocus={true}
                value={info.email}
                type="text"
                onChange={handleChange}
              />
            )}
            {selfProfile &&
              (!isEditEmail ? (
                <BsPencilSquare
                  className="edit-icon"
                  onClick={() => {
                    setIsEditEmail(!isEditEmail);
                  }}
                />
              ) : (
                <>
                  <button
                    className="edit-update-btn"
                    id="email"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                  <span
                    className="exit"
                    onClick={() => {
                      setIsEditEmail(false);
                      setInfo((pre) => {
                        return { ...pre, email: presentUser.email };
                      });
                    }}
                  >
                    {" "}
                    X{" "}
                  </span>
                </>
              ))}
          </p>
          {selfProfile && <button>Update Password</button>}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Profile;
