import React from "react";
import "../Global.css";

function DpViewer({ dpContact, setIsDpViewer }) {
  return (
    <div
      className={`dp-container`}
      onClick={() => {
        setIsDpViewer(false);
      }}
    >
      <div className="dp-viewer">
        <div className="name">{dpContact && dpContact.name}</div>
        <img
          src={dpContact && dpContact.avatarImage}
          alt=""
          srcSet=""
          width="100%"
        />
      </div>
      {console.log(dpContact)}
    </div>
  );
}

export default DpViewer;
