const host = "https://heychatserver.onrender.com";
export const registerRoute = `${host}/api/auth/register`;
export const loginRoute = `${host}/api/auth/login`;
export const getUserRoute = `${host}/api/auth/getUser`;
export const searchContactRoute = `${host}/api/auth/getSearchContact`;
export const setAvatarRoute = `${host}/api/auth/setAvatar`;
export const addContactRoute = `${host}/api/auth/addContact`;
export const updateContactRoute = `${host}/api/auth/updateContact`;
export const updateProfileImageRoute = `${host}/api/auth/updateProfileImage`;
export const getContactsRoute = `${host}/api/auth/getContacts`;
export const updateUserDetails = `${host}/api/auth/updateUserDetails`;
export const forgetPassword = `${host}/api/auth/forgetPassword`;
export const updatePassword = `${host}/api/auth/updatePassword`;

export const sendMessageRoute = `${host}/api/msgs/sendMessage`;
export const getMessagesRoute = `${host}/api/msgs/getMessages`;
export const getAllContactsRoute = `${host}/api/msgs/getAllContacts`;
