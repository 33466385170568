import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import io from "socket.io-client";
import "../Global.css";
import AddChatContainer from "../components/AddChatContainer";
import ChatBody from "../components/ChatBody";
import ChatHeader from "../components/ChatHeader";
import CheckConnection from "../components/CheckConnection";
import Contact from "../components/Contact";
import DpViewer from "../components/DpViewer";
import Profile from "../components/Profile";
import {
  addContactRoute,
  getContactsRoute,
  getUserRoute,
  sendMessageRoute,
  updateContactRoute,
} from "../utils/APIRoutes";

function ChatHome() {
  const socket = useRef();
  const contactIncludes = useRef(false);
  const rc = useRef(0);
  const navigate = useNavigate();
  const [currentContact, setCurrentContact] = useState(undefined);
  const [isSearchContact, setIsSearchContact] = useState(false);
  const [chat, setChat] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(undefined);
  const [contacts, setContacts] = useState(undefined);
  const [dpContact, setDpContact] = useState(undefined);
  const [isDpViewer, setIsDpViewer] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [isOnline, setIsOnline] = useState(undefined);

  useEffect(() => {
    const isConnected = window.navigator.onLine;
    setIsOnline(isConnected);
    console.log(isConnected);
  });

  const sortContacts = (uContact) => {
    const firstContact = JSON.parse(JSON.stringify(uContact));
    delete firstContact.name;
    delete firstContact.username;
    delete firstContact.email;
    delete firstContact.isAvatarImageSet;
    delete firstContact.avatarImage;
    console.log(firstContact);
    const updatedContacts = [firstContact];
    if (contacts && uContact) {
      contacts
        .filter((userContact) => userContact._id !== firstContact._id)
        .forEach((resultContact) => {
          const nextContact = JSON.parse(JSON.stringify(resultContact));
          delete nextContact.name;
          delete nextContact.username;
          delete nextContact.email;
          delete nextContact.isAvatarImageSet;
          delete nextContact.avatarImage;
          updatedContacts.push(nextContact);
        });
      console.log(updatedContacts);
      axios
        .post(updateContactRoute, {
          updatedContacts,
          id: JSON.parse(localStorage.getItem("chat-app-user"))._id,
        })
        .then(() => {
          axios
            .get(
              `${getContactsRoute}/${
                JSON.parse(localStorage.getItem("chat-app-user"))._id
              }`
            )
            .then((reponse) => {
              console.log(reponse.data);
              setContacts(reponse.data);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      // setContacts(updatedContacts);
    }
  };

  useEffect(() => {
    console.log("1 socket connection effect");
    socket.current = io.connect("https://heychatserver.onrender.com", {
      transports: ["websocket"],
    });
    console.log(socket.current);
  }, []);

  useEffect(() => {
    console.log("2 navigation effect");
    if (!JSON.parse(localStorage.getItem("chat-app-user"))) {
      console.log(localStorage.getItem("chat-app-user"));
      navigate("/");
    } else {
      console.log("User Login processing");
      socket.current.emit(
        "userLoggedIn",
        JSON.parse(localStorage.getItem("chat-app-user"))
      );
      console.log("user logged in");
    }
  });

  useEffect(() => {
    console.log("3 Contacts effect");
    console.log(JSON.parse(localStorage.getItem("chat-app-user")));
    !contacts &&
      localStorage.getItem("chat-app-user") &&
      axios
        .get(
          `${getContactsRoute}/${
            JSON.parse(localStorage.getItem("chat-app-user"))._id
          }`
        )
        .then((reponse) => {
          console.log(reponse.data);
          setContacts(reponse.data);
        });
  }, [contacts]);

  const sendMessage = async function (message) {
    const dt = new Date();
    const hr = dt.getHours() > 12 ? dt.getHours() % 12 : dt.getHours();
    const min = dt.getMinutes() > 9 ? dt.getMinutes() : `0${dt.getMinutes()}`;
    const time = `${hr > 9 ? hr : "0" + hr}:${min}${
      dt.getHours() > 11 ? "pm" : "am"
    }`;
    const msg = {
      message: message,
      from: JSON.parse(localStorage.getItem("chat-app-user"))._id,
      to: currentContact._id,
      date: dt,
    };
    socket.current.emit("sendMessage", {
      msg,
      currentContactId: currentContact._id,
      state: JSON.parse(localStorage.getItem("chat-app-user")),
    });
    console.log(msg);
    currentContact.lastMessage = "You : " + message;
    currentContact.unread = 0;
    sortContacts(currentContact);
    await axios.post(sendMessageRoute, msg);
    console.log(msg)
    setChat((chats) => [
      ...chats,
      {
        message: message,
        fromSelf: true,
        date: dt,
        time: time,
        consecutive:
          chat.length > 0 && chat[chat.length - 1].fromSelf ? true : false,
      },
    ]);
  };

  useEffect(() => {
    console.log("4 recieve message effect");
    socket.current.off("recieveMessage").on("recieveMessage", (data) => {
      rc.current = rc.current + 1;
      contactIncludes.current = false;
      const tempSender = data.state;
      delete tempSender.contacts;
      delete tempSender.password;
      tempSender.unread = 0;
      tempSender.lastMessage = "";
      if (contacts) {
        contacts.forEach((contact) => {
          if (contact._id === tempSender._id) {
            contactIncludes.current = true;
            tempSender.unread = contact.unread;
          }
        });
      }
      if (!contactIncludes.current) {
        axios.post(addContactRoute, {
          contact: tempSender,
          id: JSON.parse(localStorage.getItem("chat-app-user"))._id,
        });
        setContacts((userContacts) => [...userContacts, tempSender]);
      }
      console.log(data);
      setArrivalMessage(data);
    });
  }, [socket, contacts]);

  useEffect(() => {
    console.log("5 arrival message effect");

    if (
      arrivalMessage &&
      currentContact &&
      arrivalMessage.state._id === currentContact._id
    ) {
      const dt = new Date(arrivalMessage.msg.date);
      const hr = dt.getHours() > 12 ? dt.getHours() % 12 : dt.getHours();
      const min = dt.getMinutes() > 9 ? dt.getMinutes() : `0${dt.getMinutes()}`;
      const time = `${hr > 9 ? hr : "0" + hr}:${min}${
        dt.getHours() > 11 ? "pm" : "am"
      }`;
      setChat([
        ...chat,
        {
          message: arrivalMessage.msg.message,
          fromSelf: false,
          consecutive: chat[chat.length - 1].fromSelf ? false : true,
          time: time,
          date: dt,
        },
      ]);
      document.title = `uCHAT`;
      rc.current = 0;
    }
    const tempContacts =
      contacts &&
      contacts.filter((temp) => temp._id === arrivalMessage.state._id);
    if (tempContacts) {
      let t = tempContacts[0];
      if (!currentContact || t._id !== currentContact._id) {
        t = { ...t, unread: t.unread + 1, lastMessage: t.lastMessage };
      }
      t.lastMessage = arrivalMessage.msg.message;
      sortContacts(t);
    }
  }, [arrivalMessage]);

  // DP updated effect

  useEffect(() => {
    socket.current.off("dpUpdated").on("dpUpdated", (data) => {
      console.log(data);
      console.log(JSON.parse(localStorage.getItem("chat-app-user")));
      axios
        .get(
          `${getContactsRoute}/${
            JSON.parse(localStorage.getItem("chat-app-user"))._id
          }`
        )
        .then((reponse) => {
          setContacts(reponse.data);
        });
    });
  });

  const [presentUser, setCurrentUser] = useState({});
  useEffect(() => {
    axios
      .get(
        `${getUserRoute}/${
          JSON.parse(localStorage.getItem("chat-app-user"))._id
        }`
      )
      .then((data) => {
        setCurrentUser(data.data);
      });
  }, [toggleProfile]);

  return (
    <CheckConnection>
      localStorage.getItem("chat-app-user") && (
      <>
        <ToastContainer />
        {toggleProfile && (
          <Profile
            currentUser={JSON.parse(localStorage.getItem("chat-app-user"))._id}
            selfProfile={true}
            socket={socket}
            presentUser={presentUser}
            setToggleProfile={setToggleProfile}
          />
        )}
        <div className="chat-container">
          <div className="chat-left-container">
            <ChatHeader
              socket={socket.current}
              setCurrentContact={setCurrentContact}
              setToggleProfile={setToggleProfile}
              setPresentUser={setCurrentUser}
              presentUser={presentUser}
              currentUser={axios.get(
                `${getUserRoute}/${
                  JSON.parse(localStorage.getItem("chat-app-user"))._id
                }`
              )}
            />

            <div className="contacts-container">
              {contacts && contacts.length > 0
                ? contacts.map((contact, index) => {
                    const key = index + 1;
                    return (
                      <Contact
                        setDpContact={setDpContact}
                        setIsDpViewer={setIsDpViewer}
                        contact={contact}
                        currentContact={currentContact}
                        key={key}
                        setChat={setChat}
                        contacts={contacts}
                        setContacts={setContacts}
                        setCurrentContact={setCurrentContact}
                        isSearchContact={true}
                      />
                    );
                  })
                : "No contacts"}
              {dpContact && isDpViewer && (
                <DpViewer dpContact={dpContact} setIsDpViewer={setIsDpViewer} />
              )}
            </div>
            <BsFillChatLeftTextFill
              className="add-icon"
              onClick={() => {
                setIsSearchContact(!isSearchContact);
              }}
            />
            <AddChatContainer
              isSearchContact={isSearchContact}
              setIsSearchContact={setIsSearchContact}
              setChat={setChat}
              setCurrentContact={setCurrentContact}
              contacts={contacts}
              setContacts={setContacts}
            />
          </div>
          <div
            className={`chat-right-container ${!currentContact && "closed"}`}
          >
            {currentContact ? (
              <ChatBody
                currentContact={currentContact}
                setCurrentContact={setCurrentContact}
                chat={chat}
                sendMessage={sendMessage}
              />
            ) : (
              "Select a contact to start chat"
            )}
          </div>
        </div>
        {console.log("rendered")}
      </>
      )
    </CheckConnection>
  );
}

export default ChatHome;
