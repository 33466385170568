import axios from "axios";
import { React, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import "../Global.css";
import { searchContactRoute } from "../utils/APIRoutes";
import Contact from "./Contact";

function AddChatContainer({
  isSearchContact,
  setIsSearchContact,
  setCurrentContact,
  setChat,
  setContacts,
  contacts,
}) {
  const [searchContact, setSearchContact] = useState("");
  const [searchContacts, setSearchContacts] = useState([]);

  function isContactIncluded(contact) {
    const ids = [];
    contacts.forEach((element) => {
      ids.push(element._id);
    });
    return ids.includes(contact._id);
    // Some problem will accure
  }

  async function handleChange(event) {
    setSearchContact(event.target.value);
    if (event.target.value.trim().length !== 0) {
      const { data } = await axios.get(
        `${searchContactRoute}/${event.target.value}`
      );
      setSearchContacts(data);
    } else {
      setSearchContacts([]);
    }
  }
  return (
    <div className={`add-chat-container ${!isSearchContact ? "move" : ""}`}>
      <IoMdArrowBack
        className="icon"
        onClick={() => {
          setSearchContact("");
          setSearchContacts([]);
          setIsSearchContact(!isSearchContact);
        }}
      />
      <div className="input-container">
        <input
          type="text"
          placeholder="Search by username"
          name="searchContact"
          className="search-input"
          onChange={handleChange}
          value={searchContact}
        />
        <FaSearch
          className="search-icon"
          onClick={async () => {
            const { data } = await axios.get(
              `${searchContactRoute}/${searchContact}`
            );
            setSearchContacts(data);
          }}
        />
      </div>
      <div className="search-result-container">
        {searchContacts.length === 0 && searchContact.length !== 0
          ? "No contact found"
          : searchContacts.map((contact, index) => {
              const key = index + 1;
              return (
                <Contact
                  contact={contact}
                  key={key}
                  setCurrentContact={setCurrentContact}
                  contacts={contacts}
                  setContacts={setContacts}
                  setChat={setChat}
                  isSearchContact={isContactIncluded(contact)}
                  fromSearch={true}
                />
              );
            })}
      </div>
      {console.log("add-chat container rendered")}
    </div>
  );
}
export default AddChatContainer;
