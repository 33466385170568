import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Global.css";
import Logo from "../assets/logo.svg";
import Loader from "../components/Loader";
import { registerRoute } from "../utils/APIRoutes";

function Register() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("chat-app-user")) {
      navigate("/chat");
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      document.querySelector(".register-loader").classList.remove("hidden");
      setTimeout(2000);
      console.log("in validation ", registerRoute);
      const { name, username, email, password } = values;
      const { data } = await axios.post(registerRoute, {
        name,
        username,
        email,
        password,
      });
      if (data.status === "false") {
        toast.error(data.msg, toastOptions);
      }
      if (data.status === "true") {
        localStorage.setItem("chat-app-user", JSON.stringify(data.user));
      }
      navigate("/login", { state: { message: "Registration succefull..!" } });
    }
  };

  const toastOptions = {
    position: "bottom-right",
    autoClose: true,
    draggable: true,
    theme: "dark",
  };

  const [values, setValues] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleValidation = () => {
    const { name, username, email, password, confirmPassword } = values;
    if (name.length < 3) {
      toast.error("Name should containe atleast 3 characters", toastOptions);
      return false;
    } else if (password !== confirmPassword) {
      toast.error("Password and confirm password do not match", toastOptions);
      return false;
    } else if (username.length < 3) {
      toast.error("Username must be at least 3 characters", toastOptions);
      return false;
    } else if (password.length < 8) {
      toast.error("Password must be at least 8 characters", toastOptions);
      return false;
    } else if (email === "") {
      toast.error("email is required", toastOptions);
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={(event) => handleSubmit(event)}>
        <div className="brand">
          <img src={Logo} alt="" />
          <h1>CHAT</h1>
        </div>
        <input
          type="text"
          name="name"
          placeholder="Enter your name"
          onChange={(e) => handleChange(e)}
        />
        <input
          type="text"
          name="username"
          placeholder="Enter your username"
          onChange={(e) => handleChange(e)}
        />
        <div className="register-loader hidden">
          <Loader />
        </div>
        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={(e) => handleChange(e)}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={(e) => handleChange(e)}
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm password"
          onChange={(e) => handleChange(e)}
        />
        <button type="submit" name="">
          Create Account
        </button>
        <span>
          Already have an account? <Link to="/login">Login</Link>
        </span>
      </form>
    </>
  );
}

export default Register;
