import axios from "axios";
import React, { useRef } from "react";
import { IoMdPersonAdd } from "react-icons/io";
import "../Global.css";
import contactImg from "../assets/google-contacts.png";
import {
  addContactRoute,
  getMessagesRoute,
  updateContactRoute,
} from "../utils/APIRoutes";

function Contact({
  contact,
  setChat,
  currentContact,
  setCurrentContact,
  contacts,
  setContacts,
  fromSearch,
  setDpContact,
  setIsDpViewer,
  isSearchContact,
}) {
  const contactContains = useRef(false);
  const imageRef = useRef();
  const presentUser = useRef();
  return (
    <>
      <div
        className={`contact ${
          currentContact && contact && contact._id === currentContact._id
            ? "selected"
            : ""
        }`}
        onClick={async (event) => {
          if (!fromSearch) {
            console.log();
            if (!imageRef.current.contains(event.target)) {
              setCurrentContact(contact);
            }

            if (contacts) {
              contacts.forEach((userContact) => {
                if (contact._id === userContact._id) {
                  contactContains.current = true;
                }
              });
            }
            const currentUser = await JSON.parse(
              localStorage.getItem("chat-app-user")
            );
            axios
              .post(getMessagesRoute, {
                currentUser: currentUser,
                contact: contact,
              })
              .then((response) => {
                setChat(response.data);
              });
            document
              .querySelector(".add-chat-container")
              .classList.add("hidden");
            if (contacts) {
              const tempContacts = contacts.map((uContact) => {
                if (uContact._id === contact._id) {
                  return { ...uContact, unread: 0 };
                }
                return uContact;
              });
              if (currentContact) {
                currentContact.unread = 0;
              }
              axios.post(updateContactRoute, {
                updatedContacts: tempContacts,
                id: JSON.parse(localStorage.getItem("chat-app-user"))._id,
              });
              setContacts(tempContacts);
            }
          }
        }}
      >
        <div ref={imageRef}>
          <img
            onClick={(event) => {
              console.log(imageRef.current.contains(event.target));
              setDpContact({
                name: contact.name,
                avatarImage: contact.avatarImage,
              });
              setIsDpViewer(true);
            }}
            className="profile-picture"
            src={
              !contact.isAvatarImageSet ? contactImg : `${contact.avatarImage}`
            }
            alt=""
          />
        </div>
        <div className="contact-name">
          <span className="name">
            {`${contact.name} ${
              JSON.parse(localStorage.getItem("chat-app-user"))._id ===
              contact._id
                ? "(You)"
                : ""
            }`}
          </span>
          <span className="last-message">{contact.lastMessage}</span>
        </div>
        {!isSearchContact && (
          <span
            className="add-contact-icon"
            onClick={() => {
              contact.unread = 0;
              contact.lastMessage = "";
              axios.post(addContactRoute, {
                contact,
                id: JSON.parse(localStorage.getItem("chat-app-user"))._id,
              });
              setContacts((uContacts) => [contact, ...uContacts]);
              console.log(this);
            }}
          >
            <IoMdPersonAdd />
          </span>
        )}

        <div className={contact.unread > 0 ? "unread active" : "unread"}>
          {contact.unread !== 0 ? contact.unread : ""}
        </div>
      </div>
    </>
  );
}

export default Contact;
