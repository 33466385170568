import axios from "axios";
import React, { useRef, useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import { IoCloseCircle } from "react-icons/io5";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import img from "../assets/google-contacts.png";
import { updateProfileImageRoute } from "../utils/APIRoutes";
import setCanvasPreview from "./setCanvasPreview";

function ImageUploader({
  showImageUploader,
  setShowImageUploader,
  id,
  user,
  setUser,
  socket,
}) {
  const [imageUrl, setImageUrl] = useState(undefined);
  const [crop, setCrop] = useState();

  const previewCanvasRef = useRef();
  const imgRef = useRef();

  return (
    <div className={`image-uploader ${showImageUploader ? "active" : ""}`}>
      <span className="close">
        <IoCloseCircle
          onClick={() => {
            setShowImageUploader(false);
          }}
        />
      </span>
      <div className="flie-image">
        <input
          type="file"
          name=""
          id=""
          accept="image/png, image/jpg, image/jpeg"
          className=""
          onChange={(event) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              const imgPath = reader.result;
              setImageUrl(imgPath);
            });
            reader.readAsDataURL(event.target.files[0]);
          }}
        />
        <button
          onClick={() => {
            axios
              .post(updateProfileImageRoute, { imageUrl: "", id: id })
              .then((res) => {
                if (res.status === 200) {
                  let modifiedUser = user;
                  modifiedUser.isAvatarImageSet = false;
                  modifiedUser.avatarImage = "";
                  setUser(modifiedUser);
                  console.log(modifiedUser);
                  setShowImageUploader(false);
                  socket.current.emit("dp-updated");
                  setImageUrl(undefined);
                }
              });
            console.log("image deleted");
          }}
        >
          <AiTwotoneDelete />
        </button>
      </div>
      <div className="cropping-section">
        {imageUrl && (
          <div className="image-selected">
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => {
                setCrop(percentCrop);
              }}
              keepSelection
              aspect={1}
              minWidth={150}
            >
              <img
                ref={imgRef}
                src={imageUrl ? imageUrl : img}
                className="cropping-img"
                style={{ maxHeight: "50vh" }}
                onLoad={(event) => {
                  const { width, height, naturalWidth, naturalHeight } =
                    event.currentTarget;
                  console.log(width + "x" + height);
                  console.log(naturalHeight);
                  if (naturalWidth < 150 || naturalHeight < 150) {
                    console.log("Image must me 150 pixels");
                  }
                  const cropWidthInPercent = (150 / width) * 100;
                  const crop = makeAspectCrop(
                    {
                      unit: "%",
                      width: cropWidthInPercent,
                    },
                    1,
                    width,
                    height
                  );
                  const centeredCrop = centerCrop(crop, width, height);
                  setCrop(centeredCrop);
                }}
                alt=""
                srcset=""
              />
            </ReactCrop>
          </div>
        )}
        {crop && (
          <canvas
            ref={previewCanvasRef}
            className="mt-4"
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: 50,
              height: 50,
            }}
          />
        )}
      </div>
      {imageUrl && (
        <button
          onClick={(event) => {
            setCanvasPreview(
              imgRef.current,
              previewCanvasRef.current,
              convertToPixelCrop(
                crop,
                imgRef.current.width,
                imgRef.current.height
              )
            );
            const dataUrl = previewCanvasRef.current.toDataURL(
              "image/jpeg",
              0.5
            );
            console.log(dataUrl);

            axios
              .post(updateProfileImageRoute, { imageUrl: dataUrl, id: id })
              .then((res) => {
                if (res.status === 200) {
                  let modifiedUser = user;
                  modifiedUser.isAvatarImageSet = true;
                  modifiedUser.avatarImage = dataUrl;
                  setUser(modifiedUser);
                  console.log(modifiedUser);
                  setShowImageUploader(false);
                  socket.current.emit("dp-updated");
                  setImageUrl(undefined);
                }
              });
          }}
        >
          Set Picture
        </button>
      )}
    </div>
  );
}

export default ImageUploader;
