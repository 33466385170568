import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";

import Logo from "../assets/logo.svg";
import { forgetPassword, updatePassword } from "../utils/APIRoutes";

function Forget() {
  let navigate = useNavigate();

  const toastOptions = {
    position: "bottom-right",
    autoClose: true,
    draggable: true,
    theme: "dark",
  };

  const [values, setValues] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [id, setId] = useState(undefined);
  const [passwordDisable, setPasswordDisable] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);

  let handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    // console.log(values);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    setLoaderVisible(true);
    if (!passwordDisable) {
      axios.post(forgetPassword, values).then((data) => {
        console.log(data);
        if (data.data.status === 200) {
          toast.success("Detail matched. Reset your passsword");
          setId(data.data.id);
          setPasswordDisable(true);
        } else if (data.data.status === 400) {
          toast.error("No details matched with your data");
        }
        setLoaderVisible(false);
      });
    } else {
      if (values.password.length < 3) {
        toast.error("Password length should more than 3");
      } else if (values.password !== values.confirmPassword) {
        toast.error("Password and Confirm password should be same");
      } else {
        console.log(values);
        setTimeout(5000);
        axios
          .post(updatePassword, { id: id, password: values.password })
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              toast.success(data.data.msg);
              setValues({
                name: "",
                username: "",
                email: "",
                password: "",
                confirmPassword: "",
              });
              setPasswordDisable(false);
              setLoaderVisible(false);
              navigate("/login", {
                state: { message: "Pasword reset successful" },
              });
            }
          });
      }
    }

    // console.log(res);
  };

  return (
    <div>
      <ToastContainer />
      {loaderVisible && <Loader />}
      <form onSubmit={(event) => handleSubmit(event)}>
        <div className="brand">
          <img src={Logo} alt="" />
        </div>
        <div className="register-loader hidden"></div>

        {passwordDisable ? (
          <>
            <label htmlFor="">Reset your password</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={values.password}
              onChange={(e) => handleChange(e)}
            />
            <input
              type="password"
              name="confirmPassword"
              value={values.confirmPassword}
              placeholder="Confirm Password"
              onChange={(e) => handleChange(e)}
            />
            <button type="submit" name="">
              Reset
            </button>
          </>
        ) : (
          <>
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              value={values.name}
              onChange={(e) => handleChange(e)}
            />
            <input
              type="text"
              name="username"
              value={values.username}
              placeholder="Enter your username"
              onChange={(e) => handleChange(e)}
            />
            <input
              type="email"
              name="email"
              value={values.email}
              placeholder="Enter you Email"
              onChange={(e) => handleChange(e)}
            />
            <button type="submit" name="">
              Verify
            </button>
          </>
        )}
        <span>
          Login <Link to="/login">Click here</Link>
        </span>
      </form>
    </div>
  );
}

export default Forget;
