import React, { useEffect, useState } from "react";
import { IoMdClose, IoMdSend } from "react-icons/io";
import image from "../assets/google-contacts.png";
import BubbleContainer from "./BubbleContainer";
import Profile from "./Profile";
function ChatBody({ currentContact, chat, sendMessage, setCurrentContact }) {
  const [message, setMessage] = useState("");
  const [toggleProfile, setToggleProfile] = useState(false);
  useEffect(() => {
    setToggleProfile(false);
  }, [currentContact]);
  return (
    <div className="chat-body">
      {toggleProfile ? (
        <Profile
          currentUser={currentContact._id}
          setToggleProfile={setToggleProfile}
        />
      ) : (
        <>
          <div className="chat-body-header">
            <img
              className="profile-picture"
              src={
                currentContact.isAvatarImageSet
                  ? `${currentContact.avatarImage}`
                  : image
              }
              alt=""
            />
            <h1
              onClick={() => {
                setToggleProfile(!toggleProfile);
              }}
            >
              {currentContact.name}
            </h1>
            <IoMdClose
              className="chat-close-icon"
              onClick={() => {
                document.querySelector(".chat-body").classList.add("closed");
                setCurrentContact(undefined);
              }}
            />
          </div>
          <BubbleContainer chat={chat} />
          <div className="input-container">
            <textarea
              rows={1}
              type="text"
              autoFocus
              placeholder="Type your message"
              className="input-msg"
              onChange={(event) => {
                setMessage(event.target.value);
                console.log(message);
              }}
              value={message}
            ></textarea>
            <button
              onClick={() => {
                if (message.length > 0) {
                  sendMessage(message);
                }
                setMessage("");
              }}
            >
              <IoMdSend className="icon" />
            </button>
          </div>
        </>
      )}

      {/* {console.log("chat-body rendered")} */}
    </div>
  );
}

export default ChatBody;
