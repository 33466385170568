import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../assets/logo.svg";
import Loader from "../components/Loader";
import { loginRoute } from "../utils/APIRoutes";
function Login() {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (localStorage.getItem("chat-app-user")) {
      navigate("/chat");
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      document.querySelector(".register-loader").classList.remove("hidden");
      setTimeout(10000);
      console.log("in validation ", loginRoute);
      const { username, password } = values;
      const { data } = await axios.post(loginRoute, {
        username,
        password,
      });
      console.log(data);
      if (!data.status) {
        document.querySelector(".register-loader").classList.add("hidden");
        toast.error(data.msg, toastOptions);
        console.log("no matches");
      }
      if (data.status) {
        localStorage.setItem("chat-app-user", JSON.stringify(data.user));
        console.log("user saved in local storage");
        navigate("/chat", { state: data.user });
      }
    }
  };

  const toastOptions = {
    position: "bottom-right",
    autoClose: true,
    draggable: true,
    theme: "dark",
  };

  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const handleValidation = () => {
    const { username, password } = values;
    if (password === "") {
      toast.error("Password required", toastOptions);
      return false;
    } else if (username === "") {
      toast.error("Username required", toastOptions);
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={(event) => handleSubmit(event)}>
        <div className="register-loader hidden">
          <Loader />
        </div>
        <div className="brand">
          <img src={Logo} alt="" />
        </div>
        <input
          type="text"
          name="username"
          placeholder="Enter your username"
          onChange={(e) => handleChange(e)}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={(e) => handleChange(e)}
        />
        <button type="submit" name="">
          Login
        </button>
        <span>
          Don't have an account? <Link to="/register">Register here</Link>
        </span>
        <span>
          Forget password? <Link to="/forget">Click here</Link>
        </span>
        <div style={{ color: "#d9d9d9" }}>
          {state && state.message + " Login here"}
        </div>
      </form>
    </>
  );
}

export default Login;
